import axios from 'axios';
import { csrfToken } from '@rails/ujs';

axios.defaults.headers.common = {
  // 'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-TOKEN': csrfToken(),
};

axios.interceptors.response.use(
  response => response,
  error => {
  switch (error.response.status) {
    case 422:
    case 401:
    case 400:
    case 200:
      break;
    default:
      console.error(error);
      alert('通信に失敗しました。しばらくおいて再度お試しください。');
      break;
  }
  return Promise.reject(error);
})

export default axios;
